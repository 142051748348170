@font-face {
    font-family: 'Slussen-Medium';
    src: url('../public/fonts/Slussen-Medium.woff2') format('woff2'),
    url('../public/fonts/Slussen-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Slussen-Thin';
    src: url('../public/fonts/Slussen-Thin.woff2') format('woff2'),
    url('../public/fonts/Slussen-Thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html,body{
    width:100%;
    height:100%;
    font-family: "Slussen-Thin", sans-serif;
    background: #0A162E;
    color: #fff;
    font-weight: 300;
    margin: 0;
}

body {
    overflow-x: hidden;
}

a {
    color: #fff;
    text-decoration: none;
    transition: color 300ms ease-in-out;
}

a:hover {
    color: rgba(255, 255, 255, 0.75);
}

/* Google maps copyright and terms of service styles */
.gmnoprint {
    opacity: 1 !important;
}
.gmnoprint div div {
    background: #0A162E !important;
}
.gmnoprint div div,
.gmnoprint div a {
    color: rgba(255, 255, 255, 0.3) !important;
}

img[alt="Google"] {
    opacity: 0.35 !important;
}

/* CookieHub styles */
.ch2{
    font-family: "Avenir-Light", sans-serif !important;
}
.ch2 .ch2-dialog
,.ch2 .ch2-dialog-bottom
{
    background: #0A162E !important;
    box-shadow: 0px -10px 20px rgb(0 0 0 / 50%) !important
}
.ch2 .ch2-settings{
    background: #0A162E !important;
    box-shadow: 10px 10px 20px rgb(0 0 0 / 50%) !important
}
.ch2 .ch2-settings .ch2-settings-actions
{
    background: #0A162E !important;
}

.ch2 .ch2-style-dark .ch2-settings-scan .ch2-settings-tabs ul li.active a
{
    background-color: #249CFF !important;
    border: 0 !important;
}


.ch2 .ch2-btn-primary,
.ch2 .ch2-btn,
.ch2 button{

    padding: 10px 26px !important;
    border-radius: 50px;
    font-size: 18px;
    line-height: 1rem;
    color: #249CFF !important;
    border:2px solid #249CFF !important;
    background:transparent !important;
    box-shadow: none !important;
    height:auto !important;
    font-weight: normal !important;
    font-family: 'Avenir Book', sans-serif !important;
    text-decoration: none !important;
}
.ch2 .ch2-btn-primary:hover,
.ch2 .ch2-btn:hover,
.ch2 button:hover{
    background: #249CFF !important;
    color: white !important;
}
.ch2 .ch2-allow-all-btn.ch2-btn-primary
{
    background: #FF6110 !important;
    color: white !important;
    border-color: #FF6110 !important;
}


.ch2 .ch2-allow-all-btn.ch2-btn-primary:hover
{
    background: #FF986C !important;
    color: white !important;
    border-color: #FF986C !important;
}

.ch2 .ch2-deny-all-btn {
    display: none !important;
}

.ch2 .ch2-dialog-content
{
    color: rgb(194, 197, 203) !important;
}
.ch2 .ch2-style-dark .ch2-settings-content a
,.ch2 .ch2-style-dark .ch2-dialog-content a
,.ch2 .ch2-style-dark .ch2-dialog-content .ch2-learn-more
,.ch2 .ch2-style-dark .ch2-learn-more
{
    color:white !important;
    text-decoration: none !important;
}